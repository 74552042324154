<template>
  <v-alert text dense color="blue-grey" class="px-5 pt-5 mb-5">
    <v-row>
      <v-col><h4 class="black--text">Transaction Summary</h4></v-col>
    </v-row>
    <v-row class="black--text">
      <v-col lg="5" md="5">
        <!--Total-->
        <p class="mb-3">
          Amount:
          <span class="float-right">{{
            formatAsCurrency("R", value.transaction_amount)
          }}</span>
        </p>
        <!--Date-->
        <p class="mb-3">
          Date: <span class="float-right">{{ value.transaction_date }}</span>
        </p>
        <!--Type-->
        <p class="mb-3">
          Type:
          <span class="float-right">{{ value.transaction_type }}</span>
        </p>
        <!--Bank Account-->
        <p class="mb-3">
          Bank Account:
          <span class="float-right">{{ value.bank_account_name }}</span>
        </p>
      </v-col>
    </v-row>
  </v-alert>
</template>
<script>
import { formatAsCurrency } from "../../../../composables/external";
export default {
  name: "TransactionSummary",
  props: ["value"],
  data() {
    return {};
  },
  methods: {
    formatAsCurrency,
  },
};
</script>